import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import './Brc20.css'
import {linechart} from "../charts";
import { convertNumber } from "../Functions";
import { SimplifyNumber } from "../Functions";
import HeaderPages from "./Header";
import { Select } from 'antd';
import BotmPage from "./BottomPage";


  
  function _brc20() {


    const [ListTokens,SetListTokens] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [startIndex,SetStartIndex] = useState(0);
    const [sorttype,setsorttype] = useState('24hVolume')
    const nbrtokenpg =10;//put the number of token do you want to desplay in each page 



    
    const fetchoptions = {
      headers: {
        'x-access-token': process.env.REACT_APP_ApiKeyCoinrnk,
      },
    };


    const axiosoptions = {
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_ApikeyUnst}`,
        'Content-Type': 'application/json', // adjust the content type based on the API's requirements
      }
    }
    
     
    

    const fetchbrc20 = async()=>{
        try {
          
          const response = await fetch(`https://api.coinranking.com/v2/coins?tags[]=brc-20&limit=100&timePeriod=30d`, fetchoptions)
          const result = await response.json();

          SetListTokens(result.data.coins);
          setIsLoading(false);

        // result.data.coins.map((item,index)=>{
          
        //   setTimeout(async() => {
        //     const res = await TokenDetails(item.symbol)
        //     if (res) {            
        //       SetListTokens(ListTokens=>[...ListTokens,{//preserve the previous tokens in ListTokens when adding a new token,  
        //        holder:       res.holders,
        //        supply:       res.minted_supply,
        //        symbol:       item.symbol,
        //        uuid:         item.uuid,
        //        price:        item.price,
        //        iconUrl:      item.iconUrl,
        //        name:         item.name,
        //        marketCap:    item.marketCap,
        //        "24hVolume":  item["24hVolume"],
        //        sparkline:     item.sparkline
        //     }])}
        //     setIsLoading(false)
        //   }, 500* (index + 1)); //for limit request to apiurl //5 request in (s) 2 call in s
           
        // })
         
        } catch (error) {
          console.error(error); 
        }
    }
       
  //    const TokenDetails = async(_symbol) => {//return Token Details
  //     try{

  //         const Ticker = _symbol.substring(0, 4);

  //      const res = await axios.get(`https://open-api.unisat.io/v1/indexer/brc20/${Ticker}/info`, axiosoptions); // Retrieves information for BRC-20 tokens from https://api.hiro.so/ordinals/v1/brc-20/tokens?ticker=${nameoftoken}
  //      const _infotiker = res.data;
  //      if (_infotiker.data) {   

  //       const Supplyresponse = _infotiker.data.minted;          // minted supply of BRC-20 token including supply and holders
  //       const Holdersresponse= _infotiker.data.holdersCount;    // holders of BRC-20 token including supply and holders
        
  //        return {holders: Holdersresponse,minted_supply: Supplyresponse};
  
  //        }else{ 
  //          // if token doesn't a brc 20 return null for removing from array
  //              return null;
  //      }  
  //     }
  //      catch(error){
  //      return {holders:'Not available',minted_supply:'Not available'}
  // };        
  //    }

     useEffect(() => {  
      fetchbrc20()
    },[]); 

    return ( 
      <>
      <HeaderPages />
      
      {isLoading ? 
        <div className="loading-container">
          <div className="wrapper">
           <div className="circle"></div>
           <div className="circle"></div>
           <div className="circle"></div>
           <div className="shadow"></div>
           <div className="shadow"></div>
           <div className="shadow"></div>
           </div> 
          </div>
          :
        <>
          <div className="bodybrc20page">
          <div className="tabl">

           <div className="sort">
           <Select
             labelInValue
             defaultValue={{
               value: '24hVolume',
               label: '24hVolume',
              }}
              style={{
               width: 120,
               }}
              onChange={(select)=>{setsorttype(select.value)}}
              options={[
               {
                 value: '24hVolume',
                 label: '24hVolume',
                },
               {
                 value: 'marketCap',
                 label: 'marketCap',
                },
                {
                  value: 'price',
                  label: 'price',
                 },
               ]}
            />
            </div> 

            <table className="table-brc20">
              <thead>
                <tr>
                  <td>Rank</td>
                  <td>Name</td>
                  <td>Price</td>
                  <td>Volume(24h)</td>
                  <td>Market cap</td>
                  <td>Last 30 Days</td>
                </tr>
              </thead>
            <tbody>

            {ListTokens.sort((a, b) => b[sorttype] - a[sorttype]).slice(startIndex,startIndex + nbrtokenpg).map((token,x) => (
              <tr key={x} onClick={() =>  window.open(`/Brc20Info/${token.name}/${token.symbol}/${token.uuid}`)} className="tr-tokens">
                <td>{ListTokens.findIndex((object)=> object.uuid === token.uuid) + 1}</td>
                <td><div className="img_"><img src={token.iconUrl}/>{token.name}</div></td>
                <td>{"$" + ' ' + SimplifyNumber(token.price)}</td>
                <td>{"$" + ' ' + convertNumber(token['24hVolume'])}</td>
                <td>{"$" + ' ' + convertNumber(token.marketCap)}</td>
                <td>{linechart(token.sparkline, null,50)}</td>
              </tr>
            ))}
 
           </tbody>
           </table>
 
           <div className="btnswipage">

           <button onClick={()=>SetStartIndex(startIndex-nbrtokenpg)} disabled={startIndex==0}>
              <svg  width="1vw" height="2vh" stroke="currentColor" strokeWidth={1.5}  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5 4.5L3 12m0 0l7.5 7.5M3 12H21" strokeLinejoin="round" strokeLinecap="round" ></path>
              </svg>
              <span>Back</span>
           </button>

           <button onClick={()=>SetStartIndex(startIndex+nbrtokenpg)} disabled={ListTokens.length<=startIndex+nbrtokenpg}>
              <span>Next</span>
              <svg width="1vw" height="2vh" stroke="currentColor" strokeWidth={1.5}  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" strokeLinejoin="round" strokeLinecap="round" ></path>
              </svg>
           </button>

           </div> 
 
          </div>
          </div>
        </>
      }
      <div>
        <BotmPage/>
      </div>
    </>
    
     );
  }
  
  
  
  export default _brc20;