import React ,{useEffect,useState}from "react";
import axios from "axios";
import { Field, Form, Formik, ErrorMessage } from "formik";
import *as Yup from 'yup' 
import './projectsubmit.css'
import { storage } from "../firebase";
import { ref ,uploadBytes ,getDownloadURL } from "firebase/storage";
import {v4} from 'uuid'
import HeaderPages from "./Header";
import { Button, Modal } from 'antd';
import { useNavigate } from "react-router-dom";
import BotmPage from "./BottomPage";




 




//defines the validation rules for each field in the form.
const validationSchema = Yup.object().shape({
    Name: Yup.string().max(30).required('enter name!'),
    shortdesc: Yup.string().max(160).required(),
    Price: Yup.number().min(0).max(10000),
    Supply: Yup.number().integer('Please enter an integer').min(1).max(1000000000),
    Twitter: Yup.string().url('Invalid URL').required(),
    discord: Yup.string().url('Invalid URL'),
    website: Yup.string().url('Invalid URL'),
    mint_date: Yup.date().min("2022-01-01"),
    description: Yup.string().max(2000).required()
})

// initial state of the form
const initialValues = {
    Name:"",
    shortdesc:"",
    Price:"",
    Supply:"",
    Twitter:"",
    discord:"",
    website:"",
    mint_date:"",
    description:"",
}



function ProjectSubmit() {

  
  const [ishaveproject,SetIshaveProject] =useState(false);
  const [imgupload , setimageupload] = useState(null);
  const [image, setImage] = useState(null)
  const [isimguploaded , Setisimguploaded] = useState(true);
  const [isloading,SetIsLoading] =useState(false);


  const WalletAdrs = window.localStorage.getItem('Address');
  const publickey =  window.localStorage.getItem('PublicKey');

  //this for check if the wallet already has a project 
  useEffect(()=>{
    if (publickey) {
      axios.get(`https://api.inscriptions.center/Upc.ordnft.projects/${publickey}`).then(()=> 
      SetIshaveProject(true)
      ).catch((error)=>console.error(error))
    }
  },[])

   
   const navigate = useNavigate();

   

  const uploadImage =()=>{

   return new Promise((resolve, reject) => {  // using the Promise pattern to handle the asynchronous nature of the image upload process.

    if(imgupload == null){ 
      Setisimguploaded(false)
      return;
     }

     SetIsLoading(true); 

    const imageRef = ref(storage, `OrdNftProject_Img/${imgupload.name + v4()}`)  //creates a reference to the location in Firebase Storage where the image will be stored.

    uploadBytes(imageRef, imgupload).then(()=> {

      getDownloadURL(imageRef).then((url) => {  // retrieve the URL of the uploaded image.

        resolve(url);  // fulfill the Promise with the image URL
        
      }).catch((error) => {
        console.error('Error getting Image URL:', error);
        failed();
       });

    }).catch((error) => {
      console.error('Error uploading image:', error);
      failed();
    });
   });
  };
  

  useEffect(()=>{ 
   Setisimguploaded(true)  //this for handle the error message 'Image is required!' from input img
  },[imgupload])


  const success = () => {
   Modal.success({
      content: 'Project Submited',
      onOk() {
         navigate('/Me')
        }
   }); 
  };

  const failed = () => {
    Modal.error({
      content: 'failed to submit. Try again!',
      onOk() {
        window.location.reload();
        }
    });
  };


  const SendData=(data)=>{
     //if publickey not exist in locale storage show modal containe connect wallet! or alert()
     if (publickey) {
      const DataUpload = {...data}; //Creating a new data object containe the item i want to upload in database.
      delete DataUpload.mint_date; //removing the mint_date from the new obj for upload the new date with milliseconds

      DataUpload.PublicKey = publickey;
      DataUpload.address = WalletAdrs;

      if (!data.Price) {
        delete DataUpload.Price; // if i don't enterd Price it will be straing in intial valia,so i will deleted i can also convert the datatype to straing in database,
      }

      if (!data.Supply) {
        delete DataUpload.Supply; //like price
      }

      if (data.mint_date) {
        const date = new Date(data.mint_date); 
        DataUpload.Mintdate = date.getTime(); //convert date to milliseconds
      }
      
     
     uploadImage().then((res)=> { 
        DataUpload.ImgUrl = res;
        axios.post('https://api.inscriptions.center/Upc.ordnft.projects', DataUpload).then(()=>  {
         success();
        }).catch((e)=>{
          console.error(e);
          failed();
        })
     })
   }else{
      alert('Connect Wallet!')
   }
  
  }  


    return(
  <>
   <HeaderPages/>
   {isloading ? 
    <div className="loadersubmit">
      Loading
    <span className="spanloadirsubm"></span>
    </div>
   :
   <div className="ProjSubmbody">
      <div className="InputProject">
       
       <div className="UploadImg">
        {isimguploaded ? null
         : <span>Image is required!</span>
        }
          
          {image ?
          <div className="previewimg">
          <img alt="preview image" src={image}/>
          <button onClick={()=>{
            setImage(null)
            setimageupload(null)
            }}>Delete</button>
          </div>  
          :
         <label htmlFor="file" className="custum-file-upload">
           <div className="icon">
            <svg className="svguplud" viewBox="0 0 24 24" fill="" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z" fill=""></path> </g></svg>
          </div>
          <div className="text">
           <span>Click to upload image</span>
          </div>
          <input
           id="file"
          type="file"
          onChange={(event)=>{
            setimageupload(event.target.files[0])
            setImage(URL.createObjectURL(event.target.files[0])); //for Reshow the image i  selected in input field.
         }}
          accept="image/*"
          />
         </label>

         }
       </div>
 
      <Formik 
         initialValues={initialValues} 
         onSubmit={SendData} 
         validationSchema={validationSchema}
         >
        <Form>
            
            <label>Name:<span style={{ color: 'red' }}>*</span></label>
            <ErrorMessage name="Name" component="span"/>    {/* for validation errors */}
            <Field
               autoComplete ="off"
               id='inputCreatProject'
               name='Name'
               placeholder="Name"
            />
            <label>Short description :<span style={{ color: 'red' }}>*</span></label>
            <ErrorMessage name="shortdesc" component="span"/>
            <Field 
               autoComplete ="off"
               id='inputCreatProject'
               name='shortdesc'
               placeholder="Short description."
               as="textarea"
               style={{ height: 'auto', minHeight: '50px' }}
            />
            <label>Price: <span style={{ color: '#f7931a' }}>₿</span></label>
             <ErrorMessage name="Price" component="span"/>
             <Field
               autoComplete ="off"
               id='inputCreatProject'
               name='Price'
               placeholder="Price in BTC."
            />
            <label>Supply:</label>
            <ErrorMessage name="Supply" component="span"/>
            <Field
               autoComplete ="off"
               id='inputCreatProject'
               name='Supply'
               placeholder="Supply"
            />
            <label> 𝕏 :<span style={{ color: 'red' }}>*</span></label>
            <ErrorMessage name="Twitter" component="span"/>
            <Field
               autoComplete ="off"
               id='inputCreatProject'
               name='Twitter'
               placeholder="Twitter"
            />
             <label>Discord :</label>
            <ErrorMessage name="discord" component="span"/>
            <Field
               autoComplete ="off"
               id='inputCreatProject'
               name='discord'
               placeholder="Your descord."
            />
            <label>website :</label>
            <ErrorMessage name="website" component="span"/>
            <Field
               autoComplete ="off"
               id='inputCreatProject'
               name='website'
               placeholder="Your website."
            />
            <label>Mint date:</label>
            <ErrorMessage name="mint_date" component="span"/>
            <Field
               autoComplete ="off"
               id='inputCreatProject'
               name='mint_date'
               type="date"
               min="2022-01-01"  
            />
            <label>Description :<span style={{ color: 'red' }}>*</span></label>
            <ErrorMessage name="description" component="span"/>
            <Field 
               autoComplete ="off"
               id='inputCreatProject'
               name='description'
               placeholder="Description."
               as="textarea"
               style={{ height: 'auto', minHeight: '150px' }}
            />
            <button type="submit">Submit</button>

        </Form>
      </Formik>

      </div>

        <Modal title={WalletAdrs}  open={ishaveproject}  onOk={()=>navigate('/Me')} onCancel={()=>navigate('/Me')}>

          <h3>This wallet already has  a project!</h3>

        </Modal>
          <div>
            <BotmPage/>
          </div>
   </div>
   }


</>
    );

}


export default ProjectSubmit;