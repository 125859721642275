import React ,{useState, useEffect}from 'react';
import HeaderPages from './Header';
import { useParams } from 'react-router-dom';
import './ProjectInfo.css'
import CryptoJS from 'crypto-js';
import axios from 'axios'
import * as Icon from 'react-bootstrap-icons';
import { Button } from 'antd';
import { Condatemls } from '../Functions';
import BotmPage from "./BottomPage";



function ProjectInfo(){

   const EncryptPk = useParams().Encrypt_Pk;
   const _Pk = CryptoJS.AES.decrypt(atob(EncryptPk), 'NotInUrl').toString(CryptoJS.enc.Utf8) //Decrypt the Pk and use it


   const [projectinfo,SetProjectInfo] = useState({});


   


    useEffect(()=>{

       axios.get(`https://api.inscriptions.center/Upc.ordnft.projects/${_Pk}`).then((res)=>{

         SetProjectInfo(res.data);

    }).catch((e)=>console.error(e))

    },[])





    return(
        <>
          <div>
              <HeaderPages/>
          </div> 

          <div className='ProjInfoBody'>

             <div className='TitleProInfo'>
                <img src={projectinfo.ImgUrl}/>
                <div className='Titlchild'>
                    <h2>{projectinfo.Name}</h2>
                    <Button type='text' onClick={()=> window.open(projectinfo.Twitter)}><Icon.Twitter size={20}/></Button>
                    {projectinfo.discord ?  <Button type='text' onClick={()=> window.open(projectinfo.discord)}><Icon.Discord size={20}/></Button>:null}
                    {projectinfo.website ?  <Button type='text' onClick={()=> window.open(projectinfo.website)}><Icon.Globe size={20}/></Button>:null}
                </div>
             </div>

             <div>
                <table className='tablMintInf'>
                   <thead>
                    <tr>
                        <th>Mint price</th>
                        <th>Supply</th>
                        <th>Mint date</th>
                    </tr>
                   </thead>
                   <tbody>
                    <tr>
                      <td>{projectinfo.Price ? <>{projectinfo.Price} <span style={{ color: '#f7931a' }}>₿</span></>:<>{projectinfo.Price === 0 ? 'FREE' : "TBA"}</>}</td>
                      <td>{projectinfo.Supply ? <>{projectinfo.Supply}</>:'TBA'}</td>
                      <td>{projectinfo.Mintdate ? <>{Condatemls(projectinfo.Mintdate)}</>:'TBA'}</td>
                    </tr>
                   </tbody>
                </table>
             </div>

             <div className='divdescr'>
                <h2>{projectinfo.Name}</h2>
                <p>{projectinfo.shortdesc}</p>

                <h2>Project description</h2>
                <p>{projectinfo.description}</p>
             </div> 
          </div>

          <div>
            <BotmPage/>
          </div>
        </>
    );
}

export default ProjectInfo;