import React ,{useEffect, useState, useRef} from "react";
import axios from "axios";
import HeaderPages from "./Header";
import './MyAccount.css'
import { useNavigate } from "react-router-dom";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { Condatemls } from "../Functions";
import BotmPage from "./BottomPage";


const { confirm } = Modal;


function Myaccount(){

    const [project,SetProject]=useState({});
    const [ishaveproject,SetIshaveProject] =useState(false);
    const [isconnected,SetIshaveConnected] =useState(false);




    const publickey =  window.localStorage.getItem('PublicKey');
    const walletadrs =  window.localStorage.getItem('Address');

    const navigate = useNavigate();


 
    const GetProject=()=>{
            
            if (publickey) {
              SetIshaveConnected(true);
              axios.get(`https://api.inscriptions.center/Upc.ordnft.projects/${publickey}`).then((_project)=> { 

               SetProject(_project.data)
               SetIshaveProject(true)

             } 
             ).catch((error)=>console.error(error))
             }
    }



    useEffect(()=>{
            GetProject();
      },[])

      
    const success = () => {
      Modal.success({
         content: 'Delete successfully',
         onOk() {
            window.location.reload();
          }
      });
       
    };


    const DeletProject = ()=>{
        axios.delete(`https://api.inscriptions.center/Upc.ordnft.projects/${publickey}`).then(()=>{
            success();
        }).catch((error)=> console.error(error))
    }


    const showConfirm = () => { 
        confirm({
          title: 'Do you Want to delete your project?',
          icon: <ExclamationCircleFilled />,
          onOk() {
            DeletProject();
          },
          onCancel(){},
        });
      };



   
    return(
          <>
           <div>
              <HeaderPages/>
           </div> 

        <div className="bodyMyaccPage">
          {isconnected ?
          <>
          <div className="headerAdrs"><h4>{walletadrs}</h4></div>
          {ishaveproject  ?
             <div className="project_">

               <Button onClick={showConfirm} danger>Delete project</Button> 
             
               <img src={project.ImgUrl}/>

               <table  className="Info-table">
                <thead></thead>
                <tbody>
                 <tr>
                  <td>Name :</td>
                  <td>{project.Name}</td>
                 </tr>
                 <tr>
                  <td>Short description :</td>
                  <td>{project.shortdesc}</td>
                 </tr>              
                 <tr>
                  <td>Price :</td>
                  <td>{project.Price ? project.Price +' '+'BTC' :<>{project.Price === 0 ? 'FREE' : "TBA"}</>}</td>
                 </tr>
                 <tr>
                  <td>Supply :</td>
                  <td>{project.Supply ? project.Supply : "TBA"}</td>
                 </tr>
                 <tr>
                  <td>Mint Date :</td>
                  <td>{project.Mintdate ? Condatemls(project.Mintdate) : "TBA"}</td>
                 </tr>
                 <tr>
                  <td>𝕏 :</td>
                  <td><a href={project.Twitter} target="_blank"> {project.Twitter} </a></td>
                 </tr>
                 <tr>
                  <td>Discord :</td>
                  <td>{project.discord ? <a href={project.discord} target="_blank"> {project.discord} </a>: 'No discord!'}</td>
                 </tr>
                 <tr>
                  <td>Website :</td>
                  <td>{project.website ? <a href={project.website} target="_blank"> {project.website} </a>:'No website!'}</td>
                 </tr>
                 <tr>
                  <td>Description :</td>
                  <td>{project.description}</td>
                 </tr>
                 
                </tbody>
               </table>
             </div>   
           :
           <div>
             <div className="Noproj">
                <Button onClick={()=>navigate('/submit')}>New Project</Button>
             </div> 
            </div> 
           }
           </>
           :
            <div>
              <p>Connect your wallet</p>
            </div>
           }

        </div>

        <div>
          <BotmPage/>
        </div>
        </>
    );
}

export default Myaccount;