import React, { useEffect, useState, useRef } from "react";
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './HolderInsPage.css'
import HeaderPages from "./Header";
import BotmPage from "./BottomPage";
import { FloatButton } from 'antd';





function _HolderInscPage(){

  const Objadrs = useParams();
  const adress = Objadrs.adress;
  const [inscofadrs,SetInscOfAdrs] = useState([]);

const InscsOfAdrs=async(adrs)=>{
  try {
    const res = await axios.get(`https://turbo.ordinalswallet.com/wallet/${adrs}`);
   for (let i = 0; i < res.data.inscriptions.length; i++) {
    res.data.inscriptions[i].Cont_Url = `https://ordinals.com/content/${res.data.inscriptions[i].id}` //add content url to inscofadrs array 
   }
   const arrofins =  [...res.data.inscriptions];//Create a copy to avoid modifying the original array
   const GroupedByCollName =[]; // creat a new array for grouped arrofins by collection name  
   let k =0;
   for (let i = 0; i < arrofins.length;) {
    if (arrofins[i].collection !== null) { //check if the collection is not null if collection null the code will exit prematurely.
        GroupedByCollName.push([]);// creat sub-array for each unique collection name
        for (let j = i+1; j < arrofins.length;) {
          if (arrofins[j].collection !== null) {
            if (arrofins[i].collection.name===arrofins[j].collection.name) {
        GroupedByCollName[k].push(arrofins[j])   //pushes the matching elements  by name into that sub-array.
        arrofins.splice(j, 1); // removing the obj after push it to array
      }else{j++}
      }else{arrofins.splice(j, 1); }//removing the inscription that have null collection 
     }
     GroupedByCollName[k].push(arrofins[i]); // add the object that use it for compere and Add the object if  has no match 
     k++;
     i++;
    }else{arrofins.splice(i, 1)}//removing the inscription that have null collection 
     
   }
   //GroupedByCollName.sort((a, b) => b.length - a.length);//Arrange the matrix in decreasing order
   SetInscOfAdrs(GroupedByCollName);
  } catch (error) {
    console.error(error); 
  }
   
 
}



useEffect(()=>{
  InscsOfAdrs(adress); 
 },[])


return(
    <>
    <div>
      <HeaderPages/>
    </div>

    <div className="bodyholderins">

    {inscofadrs.map((group, k) => (
        <div key={k}>
          <h2>{group[0].collection.name}</h2>
          <div className="CollInsc">
          {group.map((item, x) => (
            <div  key={x} style={{ marginRight: '30px',marginBottom: '60px'}}>
              { item?.content_type.includes('image') ? (<img src ={ item?.Cont_Url} />) 
              :item?.content_type.includes('text/plain')? (<p>{item.meta?.name }</p>)
              :item?.content_type.includes('text')||item?.content_type === ' application/json'? (<iframe src={item?.Cont_Url}></iframe>)
              :item?.content_type === 'video'?(<video src={item.Cont_Url} controls />)
              :item?.content_type === 'audio' ? (<audio src={item?.Cont_Url} controls />)
              :(<img style={{width: 250,height:250,imageRendering: 'pixelated'}} src ={ item?.Cont_Url} />)
              }
            </div>
          ))}
          </div>
        </div>
      ))}
      <FloatButton.BackTop
          style={{
            right:30,
            bottom:100
            }}
      />
    </div>

    <div>
      <BotmPage/>
    </div>
    </>
)

}


export default _HolderInscPage;