import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter,RouterProvider,} from "react-router-dom";
import Home from './Pages/Home';
import ErrorPage from './Pages/error-page';
import _HolderInscPage from './Pages/HolderInsPage';
import CollectionInfoPage from './Pages/Coll_Info_Page';
import _brc20 from './Pages/Brc20';
import Brc20_info from './Pages/Brc20Info';
import IncriptionCollections from './Pages/Inscr_Coll';
import ProjectSubmit from './Pages/projectsubmit';
import Myaccount from './Pages/MyAccount.js';
import UpcomingPro from './Pages/Upco_Proj.js';
import ProjectInfo from './Pages/ProjectInfo.js';
import Docum_ from './Pages/Docum.js';





const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Me",
    element: <Myaccount/>,
  },
  {
    path: "/Submit",
    element: <ProjectSubmit/>,
  },
  {
    path: "/UpcomingProjects",
    element: <UpcomingPro/>,
  },
  {
    path: "/UpcomingProjects/:Encrypt_Pk",
    element: <ProjectInfo/>,
  },
  {
    path: "/Collections",
    element: <IncriptionCollections/>,
  },
  {
    path: "/CollectionInfo/:slug",
    element: <CollectionInfoPage/>,
  },
  {
    path: "/HolderPage/:adress",
    element: <_HolderInscPage/>,
  },
  {
    path: "/Brc20",
    element: <_brc20/>,
  },
  {
    path: "/Brc20Info/:name/:symbol/:uuid",
    element: <Brc20_info/>,
  },
  {
    path: "/Docum",
    element: <Docum_/>,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

     <RouterProvider router={router} />  // i delet <React.StrictMode></React.StrictMode> */}
     
);

reportWebVitals();
