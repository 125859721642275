import React, { useEffect, useState } from 'react';
import { json, useParams } from 'react-router-dom';
import axios from 'axios';
import {ConvDate} from '../Functions';
import './Brc20Info.css'
import { convertNumber } from '../Functions';
import { areachart } from '../charts';
import { AreaChartFillByValue } from '../charts';
import { SimpleBarChart } from '../charts';
import HeaderPages from "./Header";
import BotmPage from "./BottomPage";
import { FloatButton } from 'antd';





const Brc20_info = () => {

   const Ticker =useParams();
   const _name = Ticker.name;
   const _symbol = Ticker.symbol
   const _uuid = Ticker.uuid;


     const [brc20data,SetBrc20Data] = useState({});
     const [brc20holder,SetBrc20Holder] = useState([]);
     const [tokendata,SetTokenData] = useState({});
     const [tokenchange,Settokenchange] = useState([]);
     const [holderscount,Setholdercount]= useState([]);
     const [Avrholders,SetAvrholders]= useState([]);
     const [selectedChartTime,SetselectedChartTime] = useState('24h');


     const fetchoptions = {
      headers: {
        'x-access-token': process.env.REACT_APP_ApiKeyCoinrnk,
      },
    };


    const axiosoptions = {
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_ApikeyUnst}`,
        'Content-Type': 'application/json', // adjust the content type based on the API's requirements
      }
    }

    const TokenData =async(symbol,uuid)=>{ //Retrieves data abour  BRC-20 token including price and sparkline
      try {
        if (selectedChartTime === '1h') {
          const response = await fetch(`https://api.coinranking.com/v2/coins?uuids[]=${uuid}&symbols[]=${symbol}&timePeriod=24h`, fetchoptions)
          const Tok_data = await response.json()
          Tok_data.data.coins[0].sparkline = SparkLine1h(Tok_data.data.coins[0])//add the new sparkline that including the date of each price 
          SetTokenData(Tok_data.data.coins[0]);//i use index 0 becouse there is one obj in array
        }
        if (selectedChartTime === '8h') {
          const response = await fetch(`https://api.coinranking.com/v2/coins?uuids[]=${uuid}&symbols[]=${symbol}&timePeriod=7d`, fetchoptions)
          const Tok_data = await response.json()
          Tok_data.data.coins[0].sparkline = SparkLine8h(Tok_data.data.coins[0])//add the new sparkline that including the date of each price 
          SetTokenData(Tok_data.data.coins[0]);//i use index 0 becouse there is one obj in array
        }
        if (selectedChartTime === '24h') {
          const response = await fetch(`https://api.coinranking.com/v2/coins?uuids[]=${uuid}&symbols[]=${symbol}&timePeriod=30d`, fetchoptions)
          const Tok_data = await response.json()
          Tok_data.data.coins[0].sparkline = SparkLine24h(Tok_data.data.coins[0])//add the new sparkline that including the date of each price 
          SetTokenData(Tok_data.data.coins[0]);//i use index 0 becouse there is one obj in array
        }
      } catch (error) {
        console.error(error); 
      }
    }


    const SparkLine24h =(_tokenData)=>{// this function get the sparkline and add the date to each price in the last 30d 
      const prc =_tokenData.sparkline;
      const PrcByday = []; 
      const ChangByday = [];
      var today = new Date();
      var firstdate= new Date (today.getTime() -(1000*60*60*24*30)); //Get the date 30 days in advance
      for (let i = 0; i <= prc.length; i++) { //i should take  prc.length +1 to add another obj to PrcByday for put current price.
         var dateOfPrc = new Date(firstdate.getTime() + (1000*60*60*24*i))
         PrcByday.push({
          price: i == 30 ? _tokenData.price :prc[i], //when i =30 that is the date of today and I add the current price 
          date:dateOfPrc.getDate()+"/"+(dateOfPrc.getMonth()+1)
        })
         if (i < 30) { //PrcByday.length =30.
          if (prc[i]) {
            ChangByday.push({
              change: i == 29 ?((_tokenData.price-prc[i])/prc[i])*100 :((prc[i+1]-prc[i])/prc[i])*100, 
              date:dateOfPrc.getDate()+1+"/"+(dateOfPrc.getMonth()+1)
            })
          } else {
            ChangByday.push({
              change: 0, 
              date:dateOfPrc.getDate()+1+"/"+(dateOfPrc.getMonth()+1)
            })
          }
        }
      }
        Settokenchange(ChangByday);
        return PrcByday;
    }

    const SparkLine8h =(_tokenData)=>{// this function get the sparkline and add the date to each price in the last 30d 
      const prc =_tokenData.sparkline;
      const PrcBy8h = []; 
      const ChangBy8h = [];
      var today = new Date();
      var firstdate= new Date (today.getTime() -(1000*60*60*168)); //Get the date 7 days in advance 168h in 7 days
      for (let i = 0; i < prc.length; i++) {
         var dateofprice = new Date(firstdate.getTime() + (1000*60*60*i*8))
         PrcBy8h.push({
          price: prc[i], 
          date:dateofprice.getDate()+"/"+(dateofprice.getMonth()+1)+" "+dateofprice.getHours()+":00"} )
         if (i < prc.length-1) {
          if (prc[i]) {
            ChangBy8h.push({
              change:((prc[i+1]-prc[i])/prc[i])*100, 
              date:dateofprice.getDate()+"/"+(dateofprice.getMonth()+1)+" "+(dateofprice.getHours()+1) +":00"
            })
          } else {
            ChangBy8h.push({
              change:0, 
              date:dateofprice.getDate()+"/"+(dateofprice.getMonth()+1)+" "+(dateofprice.getHours()+1) +":00"
            })
          }
        }
      }
        Settokenchange(ChangBy8h);
        return PrcBy8h;
    }

    const SparkLine1h =(_tokenData)=>{// this function get the sparkline and add the date to each price in the last 30d 
      const prc =_tokenData.sparkline;
      const PrcBy1h = []; 
      const ChangBy1h = [];
      var today = new Date();
      var firstdate= new Date (today.getTime() -(1000*60*60*24)); //Get the date 24h in advance  
      for (let i = 0; i <= prc.length; i++) { // add current price in  current hour to PrcBy1h[]
         var dateofprice = new Date(firstdate.getTime() + (1000*60*60*i))
         PrcBy1h.push({
           // _tokenData.price is current price.
           price: i == 24 ? _tokenData.price :prc[i], 
           date:dateofprice.getHours()+":00"
         })

         if (i <= 23) { // 23 is array prc[] lenght
          if (prc[i]) {
            ChangBy1h.push({
              change: i == 23 ?((_tokenData.price-prc[i])/prc[i])*100 :((prc[i+1]-prc[i])/prc[i])*100, 
              date:dateofprice.getHours()+1 +":00"
            })
          }else{
            ChangBy1h.push({
              change: 0, 
              date:dateofprice.getHours()+1 +":00"
            })
          }
         }
      }
        Settokenchange(ChangBy1h);
        return PrcBy1h;
    }




    const TokenDitails =async(symbol)=>{//Retrieves information for a BRC-20 token including supply and holders
      try {
            const Ticker = symbol.substring(0, 4);
          
        const res = await axios.get(`https://open-api.unisat.io/v1/indexer/brc20/${Ticker}/info`,axiosoptions);
        const _infotiker = res.data;
          if (_infotiker.data) {
               const Date = ConvDate(_infotiker.data.deployBlocktime)//see ConvDate function in Functions 
               _infotiker.data.Deploy_timestamp =  Date; 

               const rescont = await axios.get(`https://ordinals.com/content/${_infotiker.data.inscriptionId}`);//get the content of inscription
               _infotiker.data.content =JSON.stringify(rescont.data);

               SetBrc20Data(_infotiker.data);
          } 
          
      } catch (error) {
        console.error(error); 
      }
        
    }


    const TokenHolder =async(symbol)=>{
      try {
        const Ticker = symbol.substring(0, 4);
          
        const res = await axios.get(`https://open-api.unisat.io/v1/indexer/brc20/${Ticker}/holders`,axiosoptions);
        const _tokenholder=res.data; 

        if (_tokenholder.data) {
           
                 for (let i = 0; i <_tokenholder.data.detail.length; i++) {
                  _tokenholder.data.detail[i].rank = i+1;
                }
                   SetBrc20Holder(_tokenholder.data.detail);
        } 
      } catch (error) {
        console.error(error); 
      }
      
    }

    const GetHoldersCount=async(_Symbol)=>{
      if (selectedChartTime==='1h') {
         const res = await axios.get(`https://server.stordata.inscriptions.center/TokenInfo/${_Symbol}?limit=24`); //retrive 24 hours of data
         Setholdercount(HoldersChart1h(res.data)) 
      }

      if (selectedChartTime==='8h') {
        const res = await axios.get(`https://server.stordata.inscriptions.center/TokenInfo/${_Symbol}?limit=168`); //retrive 168 hours of data ?7d
        Setholdercount(HoldersChart8h(res.data)) 
     }

     if (selectedChartTime==='24h') {
      const res = await axios.get(`https://server.stordata.inscriptions.center/TokenInfo/${_Symbol}?limit=720`); //retrive 720 hours of data ?30d
      Setholdercount(HoldersChart24h(res.data)) 
   }

    }


    const HoldersChart1h=(_HldrsCount)=>{
      const HooldersBy1h =[];

      for (let i = 0; i < _HldrsCount.length; i++) {
        const hldrContDate = new Date(_HldrsCount[i].date)
        HooldersBy1h.push({
           holders: _HldrsCount[i].holders, 
           date:hldrContDate.getHours()+":00",
         } 
        )
      }
      return HooldersBy1h
    }

    const HoldersChart8h=(_HldrsCount)=>{
      const HooldersBy8h =[];

      for (let i = 0; i < _HldrsCount.length; i+=8) {
        const hldrContDate = new Date(_HldrsCount[i].date)
        HooldersBy8h.push({
          holders: _HldrsCount[i].holders, 
          date:hldrContDate.getDate()+"/"+(hldrContDate.getMonth()+1)+' '+hldrContDate.getHours()+":00",
        })
      }
      return HooldersBy8h
    }

    const HoldersChart24h=(_HldrsCount)=>{
      const HooldersBy24h =[];

      for (let i = 0; i < _HldrsCount.length; i+=24) {
        const hldrContDate = new Date(_HldrsCount[i].date)
        HooldersBy24h.push({
          holders: _HldrsCount[i].holders,
          date: hldrContDate.getDate() + "/" + (hldrContDate.getMonth() + 1),
        });  
      }
      return HooldersBy24h
    }


    useEffect(()=>{
       TokenDitails(_symbol)   
       TokenHolder(_symbol)  
       TokenData(_symbol,_uuid)
       GetHoldersCount(_symbol)
    },[])

    useEffect(()=>{
      GetHoldersCount(_symbol)
      TokenData(_symbol,_uuid)
    },[selectedChartTime])


  return (
    <>
      <div className='top-page'> 
        <HeaderPages/>
      </div>
      <div className='bodypagebrcinfo'>
        <div className='tok-Img-inf'>
           < img style={{width:100,height:'auto'}} src={tokendata.iconUrl}/>
            <table>
              <thead>
              </thead>
              <tbody>
               <tr>
                <td>name :</td>
                <td>{tokendata.name}</td>
               </tr>
               <tr>
                <td>symbol :</td>
                <td>{tokendata.symbol}</td>
                </tr>
             </tbody>
           </table>
          <h3>Price :</h3>
         <h4>${convertNumber(tokendata.price)}</h4> 
         <h3>Market cap :</h3>
         <h4>${convertNumber(tokendata.marketCap)}</h4>
        </div> 
      <div className='Tb-div'>
        <div className='Tb-centered-heading'><h2 className='textb-hd'>Information</h2></div>
        <table className='Tb-Information'>
          <thead>
          </thead>
          <tbody>
          <tr>
               <td>Inscription Number :</td>
               <td>#{brc20data.inscriptionNumber}</td> 
            </tr>
            <tr>
               <td>Inscription ID :</td>
               <td>{brc20data.inscriptionId}</td> 
            </tr>
            <tr>
               <td>Max Supply :</td>
               <td>{convertNumber(brc20data.max)}</td> 
            </tr>
            <tr>
               <td>Minted :</td>
               <td>{convertNumber(brc20data.minted)}</td> 
            </tr>
            <tr>
               <td>Holders :</td>
               <td>{brc20data.holdersCount}</td> 
            </tr>
            <tr>
               <td>Avg Tokens/Holders</td>
               <td>{convertNumber(brc20data.minted / brc20data.holdersCount)}</td> 
            </tr>
            <tr>
               <td>Decimal :</td>
               <td>{brc20data.decimal}</td>
            </tr>
            <tr>
               <td>Deploy By :</td>
               <td>{brc20data.creator}</td>
            </tr>
            <tr>
               <td>Deploy Time :</td>
               <td>{brc20data.Deploy_timestamp}</td>
            </tr>
            <tr>
              <td>Limit per mint :</td>
              <td>{convertNumber(brc20data.limit)}</td>
            </tr>
            <tr>
               <td>Number of transactions :</td>
               <td>{brc20data.historyCount}</td>
            </tr>
            <tr>
               <td>Inscription Number Start :</td>
               <td>#{brc20data.inscriptionNumber}</td> 
            </tr>
            <tr>
               <td>Inscription Number End :</td>
               <td>#{brc20data.inscriptionNumberEnd}</td> 
            </tr>
            <tr>
               <td>Raw Data :</td>
               <td>{brc20data.content}</td>
            </tr>
          </tbody>
        </table>
        </div>  
         <div className='chart'>   {/* div of chart */}
          <div className='chart-title'>       
          </div>

           <div className="radio-inputs">
             <label className="radio">
             <input 
             type="radio"
             name="radio"  
             value="1h"
             onChange={(event)=>SetselectedChartTime(event.target.value)}
             />
             <span className="nameofradio">1h</span>
             </label>

             <label className="radio">
             <input 
             type="radio" 
             name="radio"
             value="8h"
             onChange={(event)=>SetselectedChartTime(event.target.value)}
             />
             <span className="nameofradio">8h</span>
             </label>
      
            <label className="radio">
           <input 
           type="radio" 
           name="radio"
           value="24h"
           defaultChecked
           onChange={(event)=>SetselectedChartTime(event.target.value)}
           />
           <span className="nameofradio">24h</span>
           </label>
          </div>

          <div className='charts'>     {/* charts */}
            <h3>PRICE</h3>
            <div style={{width:'100%' ,display:'flex',marginTop:'1%',justifyContent:'center'}}>
              <div style={{width:'45%',marginLeft:20}}>{areachart(tokendata.sparkline,"date","price",400)}</div>
              <div style={{width:'45%',marginRight:20}}>{AreaChartFillByValue(tokenchange,"date","change",400)}</div>    
            </div>
             <h3>HOLDERS</h3>
            <div style={{width:'100%' ,display:'flex',marginTop:'1%',justifyContent:'center'}}>
              <div style={{width:'45%',marginLeft:20}}> {areachart(holderscount,"date","holders",400)}</div>
              <div style={{width:'45%',marginRight:20}}>{SimpleBarChart(holderscount,"date","holders",400)}</div>    
            </div>
      
           
            
            

          </div>
          </div>

          <div>
        <div className='divtbhdr'> {/*Table of Top holder */}
         <div><h3>Holders Distribution</h3></div>
          <table>
            <thead>
              <tr>
                <td>Rank</td>
                <td>Holders</td>
                <td>Balance</td>
                <td>Value (BTC)</td>
                <td>Value ($)</td>
                <td>Percentage</td>
              </tr>
            </thead>
            <tbody>
              {brc20holder.map((holder)=>(
              <tr key={holder.rank}>
                 <td>{holder.rank}</td>
                 <td>{holder.address}</td>
                 <td>{convertNumber(holder.availableBalance)}</td>
                 <td>{convertNumber(holder.availableBalance*tokendata.btcPrice)}</td>{/* get btc value of token balance */}
                 <td>${convertNumber(holder.availableBalance*tokendata.price)}</td>{/* get btc value of token balance */}
                 <td>{(holder.availableBalance*100/brc20data.minted).toFixed(2)}%</td>{/* Percentage */}
              </tr>
              ))}
            </tbody>
          </table>
        </div>
        </div>
          <FloatButton.BackTop
            style={{
              right:30,
              bottom:100
              }}
           />
        </div>

        <div>
          <BotmPage/>
        </div>
    </>
    );
};

export default Brc20_info;