import React ,{useEffect, useState, useRef}from "react";
import { Button , Modal} from "antd"
import Walletinfo from "./WalletInfo";


function Unisatwallet(){

    const [unisatInstalled, setUnisatInstalled] = useState(false)
    const [connected, setConnected] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [address, setAddress] = useState("")


    const [ismodelinstallopen, SetIsModelInstallOpen] = useState(false);


    const WalletAdrs = window.localStorage.getItem('Address');



    const getBasicInfo=async()=>{
        try {
          const unisat = window.unisat;

          window.localStorage.setItem('WalletName','unisat');
    
          let [_address] = await unisat.getAccounts();
          window.localStorage.setItem('Address',_address);
    
          let _publickey = await unisat.getPublicKey();
          window.localStorage.setItem('PublicKey',_publickey);
    
        } catch (e) {
          console.log("can't get the informations")
        }
         
      }


      const selfRef = useRef({
        accounts: []
      })
    
      const self = selfRef.current
    
      const handleAccountsChanged = _accounts => {

        if (self.accounts[0] === _accounts[0]) {
          // prevent from triggering twice
          return
        }

        self.accounts = _accounts

        if (_accounts.length > 0) {

          setAccounts(_accounts)
          setConnected(true)
          setAddress(_accounts[0])

    
          getBasicInfo()

        } else {
          setConnected(false)

          window.localStorage.setItem('WalletName','');
          window.localStorage.setItem('Address','');
          window.localStorage.setItem('PublicKey','');

          window.dispatchEvent(new Event("storage")); //dispatch a custom "storage" event on the window object //that means somting change in Localestorage
        }
      }

    
      
      const conctUnisatwlt = async()=>{
        try {
            
            let adrs = await  window.unisat.requestAccounts();
            handleAccountsChanged(adrs)
            window.location.reload();
            
        } catch (e) {
            //reload the page when i cancel request for i can connect wallet again 
            if (typeof window.unisat !== 'undefined') {//i want to know if the error from install wallet 
              window.location.reload();
            }
            SetIsModelInstallOpen(true) // if the error from  install wallet 
        }
      }

      useEffect(()=>{ //connect wallet when initialize <UnisatWallet/>
        if (!connected && (WalletAdrs==='' || WalletAdrs ===null)) {
          conctUnisatwlt()
        }   
       },[])

       useEffect(()=>{ 
         if (address ==='') {
          setAddress(WalletAdrs) //if open browser again address will be not available then put the last address was connected from lacalstarage.
         }
       },[])

    
    
    
      useEffect(() => {
        async function checkUnisat() {
    
          let unisat = window.unisat
    
          for (let i = 1; i < 10 && !unisat; i += 1) {
            await new Promise(resolve => setTimeout(resolve, 100 * i))
            unisat = window.unisat
          }
      
          if (unisat) {
            setUnisatInstalled(true)
          } else if (!unisat) return

        
             unisat.getAccounts().then((accounts) => {
            handleAccountsChanged(accounts)
          })
          
         
    
          unisat.on("accountsChanged", handleAccountsChanged)
    
          return () => {
            unisat.removeListener("accountsChanged", handleAccountsChanged)
          }
        }
    
        checkUnisat().then()
      }, [])

       
  
    

      
      if (!unisatInstalled) {
        return (
         <div>
            <Modal title="Install Wallet" footer="" centered open={ismodelinstallopen}  onCancel={()=> {window.location.reload();SetIsModelInstallOpen(false)}}>

                <Button onClick={()=> {window.location.href='https://www.xverse.app/download' ; SetIsModelInstallOpen(false)}} >Install Unisat</Button>

            </Modal>
         </div>
        )
      }
    
    
      return (
        <>
          <div>
            <Walletinfo adrs={address}/>
          </div>
       </>
      )
     
    
    }
    
    export default Unisatwallet;



