import React ,{useEffect, useState} from "react";
import { getAddress } from 'sats-connect'
import { Button , Modal} from "antd";
import Walletinfo from "./WalletInfo";



function Xverswallet(){

    const [XversInstalled, SetXvesInstalled] = useState(true);
    const [ismodelinstallopen, SetIsModelInstallOpen] = useState(false);



    const WalletAdrs = window.localStorage.getItem('Address');
    const WalletName = window.localStorage.getItem('WalletName');


    //connect wallet //from xvers Docs
    const getAddressOptions = {
        payload: {
          purposes: ['ordinals'],
          message: 'Address for receiving Ordinals and payments',
          network: {
            type:'Mainnet'
          },
        },
        onFinish: (response) => {

          window.localStorage.setItem('WalletName','Xvers');
          window.localStorage.setItem('Address',response.addresses[0].address);
          window.localStorage.setItem('PublicKey',response.addresses[0].publicKey);

          window.dispatchEvent(new Event("storage"));
        },
        onCancel: () => {
          alert('Request canceled')
          window.location.reload()
        } ,
        }
         
        //run automatically when call this page 
        const ConnectXvers=async()=>{
            try {
                  await getAddress(getAddressOptions);
            } catch (error) {
                console.error(error);//error if wallet not instaled!
                   SetXvesInstalled(false) 
                   SetIsModelInstallOpen(true)
            }
          
        }

        useEffect(()=>{
          if (WalletAdrs==='' || WalletAdrs ===null) {
               ConnectXvers();
          }
        },[])
          
    
    return(
        <>
        {XversInstalled ? 
         <div>
            <Walletinfo adrs={WalletAdrs}/>
         </div>
         :
         <div>
            <Modal title="Install Wallet" footer="" centered open={ismodelinstallopen}  onCancel={()=> {window.location.reload();SetIsModelInstallOpen(false)}}>

                <Button onClick={()=> {window.location.href='https://www.xverse.app/download' ; SetIsModelInstallOpen(false)}} >Install Xvers</Button>

            </Modal>
         </div>
         }
        </>
    );
}


export default Xverswallet;