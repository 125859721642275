import React from "react";
import HeaderPages from "./Header";
import BotmPage from "./BottomPage";
import './Docum.css'


function Docum_(){
    return(
        <>
        <div> 
           <HeaderPages/>
        </div>

        <div className="bodypage">
        <div className="btext">
        <h2>What Are Bitcoin Ordinals?</h2>

        <p>A Bitcoin ordinal is a new type of non-fungible token (NFT) that is inscribed on the Bitcoin blockchain. This means that each ordinal is a unique and verifiable piece of digital content that is permanently stored on the Bitcoin blockchain. Bitcoin ordinals are created by attaching data to a specific satoshi, which is the smallest unit of Bitcoin. This data can be anything, such as an image, a video, or a piece of text. Once a satoshi has been inscribed with data, it becomes a unique ordinal and can be traded or collected like any other NFT.</p>
        <h3>Bitcoin Ordinals: A New Era of NFTs on the Bitcoin Blockchain.</h3>
        <p>Emerging from the depths of the Bitcoin network, Bitcoin ordinals represent a novel form of non-fungible tokens (NFTs) permanently etched onto the Bitcoin blockchain. Each ordinal stands as a unique, verifiable piece of digital content, wielding the immutability and security of the Bitcoin blockchain. By attaching data, ranging from images and videos to text, to a specific satoshi, the smallest unit of Bitcoin, ordinals are born. This fusion of data and satoshi transforms it into a distinct ordinal, ready to be traded and collected like any other NFT.</p>
        <p>Despite their recent inception, Bitcoin ordinals have sparked fervent discussions regarding their potential impact on the Bitcoin ecosystem. Proponents envision ordinals revitalizing the Bitcoin network, attracting new users and ushering in a new era of digital collectibles. On the other hand, concerns arise about the potential for ordinals to exacerbate spam and bloat, straining the network's resources.</p>
        <h3>key benefits of Bitcoin ordinals:</h3>

        <div className="keychar">
          <ul>
          <li><span>Scarcity:</span> Bitcoin ordinals are inherently scarce, as there are only a finite number of satoshis available. This scarcity makes them more valuable than other types of NFTs, which can be minted in unlimited quantities.</li>
          <li><span>Security:</span>  Bitcoin ordinals are stored on the Bitcoin blockchain, which is one of the most secure and decentralized networks in the world. This means that they are extremely resistant to fraud and censorship.</li>
          <li><span>Accessibility:</span> Bitcoin ordinals can be created and traded using any Bitcoin wallet, which makes them accessible to a large number of people.</li>
         </ul>
        </div>

        <h3>Potential Risks of Bitcoin Ordinals:</h3>
        <div className="keychar">
          <ul>
          <li><span>Spam:</span> The ability to attach data to satoshis carries the risk of spam flooding the Bitcoin network, hindering its performance and increasing costs.</li>
          <li><span>Bloat:</span>   The influx of data associated with ordinals could lead to an expansion of the Bitcoin blockchain, making it more cumbersome and expensive to store and synchronize.</li>
          <li><span>Centralization:</span> The development of Bitcoin ordinals might concentrate power in the hands of a few large miners, potentially compromising the decentralized nature of Bitcoin.</li>
         </ul>
        </div>
         <hr/>
         <h2>What are the inscriptions?</h2>
         <p>Bitcoin Ordinals inscription is the process of embedding data into individual satoshis, the smallest unit of Bitcoin. This data can be anything from an image or video to a piece of text or even a script. Once a satoshi has been inscribed with data, it becomes a unique and verifiable piece of digital content that is permanently stored on the Bitcoin blockchain. This makes Bitcoin Ordinals a valuable tool for creating non-fungible tokens (NFTs) and other types of digital collectibles. </p>
         <p>Bitcoin Ordinals inscriptions are a new and exciting technology with the potential to revolutionize the way we create and interact with digital content. By making it possible to embed data into satoshis, Bitcoin Ordinals provide a secure and immutable way to store and share digital assets.</p>
         
         <h3>the potential benefits of using Bitcoin Ordinals inscriptions:</h3>
         <div className="keychar">
          <ul>
          <li><span>Security:</span>Bitcoin Ordinals inscriptions are stored on the Bitcoin blockchain, which is one of the most secure and decentralized networks in the world. This means that they are extremely resistant to fraud and censorship.</li>
          <li><span>Immutability:</span>  Once data has been inscribed into a satoshi, it cannot be changed or deleted. This makes Bitcoin Ordinals inscriptions a valuable tool for creating permanent and immutable records.</li>
          <li><span>Scarcity:</span> Bitcoin Ordinals inscriptions are inherently scarce, as there are only a finite number of satoshis available. This scarcity makes them more valuable than other types of NFTs, which can be minted in unlimited quantities.</li>
          <li><span>Accessibility:</span> Bitcoin Ordinals inscriptions can be created and traded using any Bitcoin wallet, which makes them accessible to a large number of people.</li>
         </ul>
        </div>
        <hr/>
        <h2>What Is the BRC-20 Token Standard?</h2>
       <p>
       BRC-20 is an experimental token standard that enables the minting and transfer of fungible tokens on the Bitcoin blockchain. 
       It is similar to the ERC-20 token standard on Ethereum and EVM blockchain networks. BRC-20 tokens are created through JSON inscriptions on satoshis through Bitcoin ordinals.
        They resemble smart contract tokens with the JSON defining the core features of the BRC-20 token.
       </p>
       <h3>key characteristics of BRC-20 tokens:</h3>

        <div className="keychar">
          <ul>
          <li><span>Fungible:</span> BRC-20 tokens are fungible, meaning that they are identical and interchangeable with one another. This makes them well-suited for use as a medium of exchange.</li>
          <li><span>Transferable:</span> BRC-20 tokens can be easily transferred between wallets. This makes them liquid and easy to use</li>
          <li><span>Mintable:</span> BRC-20 tokens can be minted by anyone with access to the Bitcoin blockchain. This makes them accessible to a wide range of users.</li>
          <li><span>Secure:</span> BRC-20 tokens are secured by the Bitcoin blockchain, which is one of the most secure blockchain networks in the world.</li>
         </ul>
        </div>

        <h3>potential use cases for BRC-20 tokens:</h3>

        <div className="keychar">
          <ul>
          <li><span>Decentralized exchanges (DEXs): </span>  BRC-20 tokens could be used to create new DEXs that are more efficient and user-friendly than existing DEXs.</li>
          <li><span>Stablecoins:</span> BRC-20 tokens could be used to create new stablecoins that are pegged to fiat currencies or other assets.</li>
          <li><span>Non-fungible tokens (NFTs):</span> BRC-20 tokens could be used to create new types of NFTs that have additional functionality.</li>
         </ul>
        </div>
        
        <h3>Creation of BRC-20 Tokens.</h3>
           <p>BRC-20 tokens are created by attaching a JSON code to satoshis using the ordinals protocol. This JSON code contains executable code fragments that define the token's characteristics, including its supply, minting limit, and unique identity.</p>

        <h3>BRC-20 Tokens: Expanding the Possibilities of the Bitcoin Blockchain.</h3>
        <p>Satoshis, the fundamental units of the Bitcoin network, can now be utilized to create fungible tokens known as BRC-20 tokens. This development is facilitated by the Taproot upgrade and the ordinals protocol, which enables the attachment of additional data to satoshis.</p>
        
        <h3>Applications of BRC-20 Tokens:</h3>

        <div className="keychar">
          <ul>
          <li><span>P2P Transfers: </span>  BRC-20 tokens could be used to create new DEXs that are more efficient and user-friendly than existing DEXs.</li>
          <li><span>Decentralized Finance (DeFi):</span> BRC-20 tokens could be used to create new stablecoins that are pegged to fiat currencies or other assets.</li>
          <li><span>Tokenization:</span> BRC-20 tokens could be used to create new types of NFTs that have additional functionality.</li>
         </ul>
        </div>
      </div>
      </div>
        <div>
        <BotmPage/>
        </div>
        </>
    );
}

export default Docum_;