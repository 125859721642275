import React, { useEffect, useState, useRef } from "react";
import { TwitterOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';


function BotmPage(){



    return(
        <div style={{marginTop:'5%'}}>
            <FloatButton
              onClick={()=>window.open('https://twitter.com/Inscr_center', '_blank')}
              shape="circle"
              type="primary"
              style={{
              right:30,
              bottom:30
              }}
              icon={<TwitterOutlined />}
            /> 
        </div>
    );
}

export default BotmPage;