import React, { useEffect, useState, useRef } from "react";
import ReactDOM from 'react-dom/client';
import './Coll_info_page.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {useParams} from 'react-router-dom';
import HeaderPages from "./Header";
import BotmPage from "./BottomPage";
import { FloatButton } from 'antd';






function CollectionInfoPage(){

  const Slug=useParams();
  const _slug = Slug.slug;

  const [holdercounts,SetHolderCounts] = useState([]);   
  const [colinfo,SetColInfo] = useState({});
  const [isloading,Setisloading]= useState(true);



 const _collinfo =async(slg)=>{
    try {
         const res  = await axios.get(`https://turbo.ordinalswallet.com/collection/${slg}`);
         const _res  = await axios.get(`https://turbo.ordinalswallet.com/collection/${slg}/stats`);
         res.data.owners = _res.data.owners;
         SetColInfo(res.data);
     } catch (error) {
      console.error(error)
    }

 } 
 




 const _TopHolder=async(slg)=>{
    let ArrOfHolder =[];
    try {
   const res = await axios.get(`https://turbo.ordinalswallet.com/collection/${slg}/snapshot`);
    const ListOfHolder = res.data; 
    ArrOfHolder = ListOfHolder.split('\n'); // splits the string ListOfHolder into a list of strings, separated by newline characters (\n). The result is assigned to the variable ArrOfHolder.


       const Holder_Counts = new Array().fill({});  
       let i =0;
        for (let j=0;j<ArrOfHolder.length;j++) {
          if (Holder_Counts.find((obj) => obj.Holder === ArrOfHolder[j])) {
           let x =Holder_Counts.findIndex((obj) => obj.Holder === ArrOfHolder[j]);// index of obj that contain the adress of holder
            Holder_Counts[x] = {Holder:ArrOfHolder[j], Owns:Holder_Counts[x].Owns+1} 

          } else {
            Holder_Counts[i] = { Holder: ArrOfHolder[j], Owns:1};
            i++;
          }
        }
        Holder_Counts.sort((a, b) => b.Owns - a.Owns); //Arrange the matrix in decreasing order
        Holder_Counts.forEach((object, index) => object.rank = index + 1);

        SetHolderCounts(Holder_Counts.slice(0,30));
        Setisloading(false);

    } catch (error) {
      console.error('there is no holder information for this colliction',error); 
      SetHolderCounts(null);
      Setisloading(false);
    }
   
   
 }


  useEffect(()=>{
    _TopHolder(_slug);
    _collinfo(_slug)
  },[])



  return(
   <>
     <HeaderPages/>

   <div className="pagehead">
   </div>

   <div className="bodypagecollinfo">

    <div className="Title_Collinfo">
      
       <h1>{colinfo.name}</h1>
       <img src={colinfo.icon}/>

    </div>
    <div>
       <table className="Table_Collinfo">
        <thead>
          <tr>
            <td>Total Supplay</td>
            <td>Holders</td>
            <td>Range</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{colinfo.total_supply}</td>
            <td>{colinfo.owners}</td>
            <td>{colinfo.lowest_inscription_num +'  '}To{'  '+colinfo.highest_inscription_num}</td>
          </tr>
        </tbody>
       </table>
   </div>

    <div  className="tableholder">
      <h3>Holders Distribution</h3>
      {isloading ?
      <div className="loadTpHolder">
        <svg className="loadtphdsvg" viewBox="25 25 50 50">
         <circle  className="loadtphdcrcl"r="20" cy="50" cx="50"></circle>
        </svg>
      </div>
      :
      <>
    {holdercounts !== null?
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Holder</th>
            <th>Owns</th>
            <th>Percentage</th>
          </tr>
        </thead>
        <tbody>
          {holdercounts.map((obj)=>(
            <tr key={obj.rank} onClick={()=>window.open(`/HolderPage/${obj.Holder}`)}>
              <td>{holdercounts.findIndex((object)=> object.Holder === obj.Holder) + 1}</td>
              <td>{obj.Holder}</td>
              <td>{obj.Owns}</td>
              <td>{((obj.Owns*100)/colinfo.total_supply).toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    :<p>Not available</p>}</>}
    </div>
      <FloatButton.BackTop
          style={{
            right:30,
            bottom:100
            }}
       />
    </div>

    <div>
      <BotmPage/>
    </div>
   </>
  );

}

export default CollectionInfoPage;