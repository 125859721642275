import React, { useEffect, useState } from "react";
import './Home.css'
import HeaderPages from "./Header";
import { SimpleTreemap } from "../charts";
import { Carousel } from 'antd';
import BotmPage from "./BottomPage";




function Home() {

  const fetchoptions = {
    headers: {
      'x-access-token': process.env.REACT_APP_ApiKeyCoinrnk,
    },
  };
  
  const [tokenscap,setTokensCap]=useState([]);
  const [tokensprice,setTokensPece]=useState([]);
  const [tokenschange,setTokensChange]=useState([]);




  const fetchbrc20 = async()=>{
    try {
      const _tokensCap=[];
      const _tokensprice =[];
      const _tokensChange =[];
      
      const response = await fetch(`https://api.coinranking.com/v2/coins?tags[]=brc-20&limit=100`, fetchoptions)
      const result = await response.json();
      for (let i = 0; i < result.data.coins.length; i++) {

         _tokensCap.push({
          name: result.data.coins[i].symbol,
          marketCap:parseInt(result.data.coins[i].marketCap)
        })

        _tokensprice.push({
          name: result.data.coins[i].symbol,
          Price:parseFloat(parseFloat((result.data.coins[i].price)).toFixed(2))
        })

        _tokensChange.push({
          name: result.data.coins[i].symbol,
          Price:parseFloat(parseFloat((result.data.coins[i].price)).toFixed(4)),
          Change:parseFloat(parseFloat((result.data.coins[i].change)).toFixed(2))
        })
        }

       _tokensChange.sort((a, b) => b['24hVolume'] - a['24hVolume']); //change for the top token 24h volume
       setTokensChange(_tokensChange.slice(0, 5));

       _tokensCap.sort((a, b) => b['marketCap'] - a['marketCap']); 
       setTokensCap(_tokensCap.slice(0, 10))
 
       _tokensprice.sort((a, b) => b['Price'] - a['Price']); 
       setTokensPece(_tokensprice.slice(0, 8))

    } catch (error) {
      console.error(error); 
    }
}


useEffect(()=>{
  fetchbrc20()
},[])

  return (
   <>
   <HeaderPages/>
   
   <div className="bodypage">

   <div className="CarouselDiv">
      <Carousel autoplay>
          <div>
            <div className="contentStyle">
              <h3>{tokenschange[0]?.name}</h3> 
             <div style={{display:'flex',  justifyContent: 'center', alignItems: 'center'}}>
              <h3 style={{marginRight:'1%'}}>{tokenschange[0]?.Price}$</h3> 
              <h3  style={{marginLeft:'1%', color: tokenschange[0]?.Change>=0 ? 'green' :'red' }}>{tokenschange[0]?.Change<0 ? tokenschange[0]?.Change:'+'+tokenschange[0]?.Change+'%'}</h3>
             </div>        
            </div> 
          </div>

          <div>
             <div className="contentStyle">
               <h3>{tokenschange[1]?.name}</h3> 
               <div style={{display:'flex',  justifyContent: 'center', alignItems: 'center'}}>
               <h3 style={{marginRight:'1%'}}>{tokenschange[1]?.Price}$</h3> 
               <h3  style={{marginLeft:'1%' , color: tokenschange[1]?.Change>=0 ? 'green' :'red' }}>{tokenschange[1]?.Change<0 ? tokenschange[1]?.Change:'+'+tokenschange[1]?.Change+'%'}</h3>
              </div>        
             </div> 
          </div>

          <div>
              <div className="contentStyle">
               <h3>{tokenschange[2]?.name}</h3> 
               <div style={{display:'flex',  justifyContent: 'center', alignItems: 'center'}}>
                <h3 style={{marginRight:'1%'}}>{tokenschange[2]?.Price}$</h3> 
                <h3  style={{marginLeft:'1%' , color: tokenschange[2]?.Change>=0 ? 'green' :'red' }}>{tokenschange[2]?.Change<0 ? tokenschange[2]?.Change:'+'+tokenschange[2]?.Change+'%'}</h3>
               </div>        
            </div> 
          </div>

          <div>
            <div className="contentStyle">
              <h3>{tokenschange[3]?.name}</h3> 
             <div style={{display:'flex',  justifyContent: 'center', alignItems: 'center'}}>
              <h3 style={{marginRight:'1%'}}>{tokenschange[3]?.Price}$</h3> 
              <h3  style={{ marginLeft:'1%', color: tokenschange[3]?.Change>=0 ? 'green' :'red' }}>{tokenschange[3]?.Change<0 ? tokenschange[3]?.Change:'+'+tokenschange[3]?.Change+'%'}</h3>
             </div>        
            </div> 
         </div>
         <div>
         <div className="contentStyle">
              <h3>{tokenschange[4]?.name}</h3> 
             <div style={{display:'flex',  justifyContent: 'center', alignItems: 'center'}}>
              <h3 style={{marginRight:'1%'}}>{tokenschange[4]?.Price}$</h3> 
              <h3  style={{marginLeft:'1%' , color: tokenschange[4]?.Change>=0 ? 'green' :'red' }}>{tokenschange[4]?.Change<0 ? tokenschange[4]?.Change:'+'+tokenschange[4]?.Change+'%'}</h3>
             </div>        
            </div> 
         </div>
      </Carousel>
   </div>

    <div className="treemap_chart">    
        <div style={{width:'45%', textAlign:'center',marginRight:'1%'}}>
           <h3>MarketCap</h3>
          {SimpleTreemap(tokenscap,"marketCap",500,'rgb(0, 89, 32)')}
          </div> 
        <div style={{width:'45%', textAlign:'center',marginLeft:'1%'}}>
          <h3>Price</h3>
          {SimpleTreemap(tokensprice,"Price",500,'rgb(0, 168, 50)')}
       </div>
    </div>     
   </div>
   <div>
    <BotmPage/>
   </div>
 </>
   );
}



export default Home;