import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage"



const firebaseConfig = {
  apiKey: process.env.REACT_APP_Firebase_apiKey,
  authDomain: "inscriptionscenter-2ef13.firebaseapp.com",
  projectId: "inscriptionscenter-2ef13",
  storageBucket: "inscriptionscenter-2ef13.appspot.com",
  messagingSenderId: "21851344492",
  appId: process.env.REACT_APP_Firebase_appId,
  measurementId: "G-TTTFN89FPK"
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);