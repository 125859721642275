import React from "react";
import { Link } from 'react-router-dom';
import './Header.css'
import Wallet from "./Wallets";


function HeaderPages(){



    return(
        <>
        <div className="headerpage">
            
        <img src={require('./Inscrcenterlogo_500.png')}/>

        <Link to={'/'}>Home</Link>
        <Link to={'/UpcomingProjects'}>Upcoming projects</Link>
        <Link to={'/Brc20'}>Brc20</Link>
        <Link to={'/Collections'}>Collections</Link>
        <Link to={'/submit'}>Submit project</Link>

         <div className="ConnectWlt">
           <Wallet />
        </div>
        </div>
        </>
    );
}



export default HeaderPages;