import React, { useEffect, useState } from "react";
import HeaderPages from "./Header";
import axios from "axios";
import './Upco_Proj.css'
import { Condatemls } from "../Functions";
import { Button } from "antd";
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import BotmPage from "./BottomPage";
import { FloatButton } from 'antd';







function UpcomingPro (){

    const [Project,SetProjets] = useState([]);
    const [endindexing, setendindexing] = useState(5);
    const [isdisabed, setisdisabed] = useState(false);
    const [isloading, setisloading] = useState(true); 

    const navigate = useNavigate();


    useEffect(()=>{
        axios.get(`https://api.inscriptions.center/Upc.ordnft.projects`).then((res)=> { 
             res.data.sort((a, b) => {
                if (a.Mintdate === null && b.Mintdate === null) {//Sort from near date to distant date
                  return 0;
                }
                if (a.Mintdate === null) {
                  return 1; // Move objects with null Mintdate to the bottom
                }
                if (b.Mintdate === null) {
                  return -1; // Move objects with null Mintdate to the bottom
                }
                return a.Mintdate - b.Mintdate;
              });
              setisloading(false);
              SetProjets(res.data);
        }).catch((e)=> console.error(e))
    },[])

    
    const handleReadMoreClick = () => { //show Project 5 by 5.
      const numberOfProjs = Project.length;
      setendindexing(endindexing + 5); //add 5 for display new 5 projects.

      if (numberOfProjs <= endindexing +5) { //end of project 
        setisdisabed(true);
      }
    };


    return(
        <>
          <div>
              <HeaderPages/>
          </div> 

          <div className="UpcoProBody">
            <div className="upcTitle">
              <h2>Upcoming Ordinals</h2>
            </div>

            {isloading ? 
              <div className="loaderUpcOrd"></div>
             :
             <>
             {Project.length>0 ? 
              <div>
               {Project.slice(0,endindexing).map((item, x)=>{return (
                <div key={x}>
                   <table className="TableProjs" onClick={()=>navigate(`/UpcomingProjects/${btoa(CryptoJS.AES.encrypt(item.PublicKey, 'NotInUrl').toString())}`)} /* I don't want the PK to be in url then i encrepted using CryptoJS library */> 
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td rowSpan={2}><img src={item.ImgUrl}/></td>
                        <td>Name</td>
                        <td>Price</td>
                        <td>Supply</td>
                        <td>Mint date</td>
                      </tr>
                        <tr>
                          <td>{item.Name}</td>
                          <td>{item.Price ? item.Price +' '+'BTC' : <>{item.Price === 0 ? 'FREE' : "TBA"}</>} </td>
                          <td>{item.Supply ? item.Supply : "TBA"}</td>
                          <td>{item.Mintdate ? Condatemls(item.Mintdate) : "TBA"}</td>
                        </tr>
                    </tbody>
                   </table>
                </div>
               )})}
               {Project.length > 5 ?
                 <div className="ReadMoreBtn">
                   <Button  onClick={handleReadMoreClick} disabled={isdisabed}>More <DownOutlined/></Button>
                 </div>
                   :null
               }
            </div>       
            :
            <div className="NoProjYet">
              <h2>No projects are brewing at the moment, but keep an eye out for future updates!</h2>
            </div>
            }</>}

             <FloatButton.BackTop
              style={{
                right:30,
                bottom:100
               }}
            /> 
          </div>
          <div>
            <BotmPage/>
          </div>
        </>
    );
}


export default UpcomingPro;