import { LineChart, Line } from "recharts";
import {
  AreaChart,Area,XAxis,YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart, Bar, Rectangle,Legend, 
  Treemap, 
} from "recharts";



export function linechart(Data,DataKey,Height){// take data array , data key and style of chart   //Key data are the points on which the graph is plotted
  if (Data && Data.length>0) {
      if (DataKey) {
    return (      
         //width of chart will be 100% of the  container and height will be put it manialy      
      <ResponsiveContainer width="100%"  height={Height}>                                                  
        <LineChart data={Data}>
          <Line type="monotone" dataKey="DataKey" stroke="#8884d8" strokeWidth={2} />         
        </LineChart>
        </ResponsiveContainer>
      );
   } else {//if the array was array of number without obj
     const transformedData = Data.map(x => x!=null ? x*10 : null ).map((x) => ({ pv: x }));  //multiplication of every number of arr for fix the small number to get good chart () and transform the Data array into an array of objects, each with a single property named dataKey (pv).

    return (     
      //width of chart will be 100% of the  container and height will be put it manialy
      <ResponsiveContainer width="100%" height={Height}>                               
        <LineChart data={transformedData}>
          <Line type="monotone" dataKey="pv" stroke="#8884d8" strokeWidth={2} dot={false}/>         
        </LineChart>
        </ResponsiveContainer> 
      );
   }
  } else {
    <h1>Not available</h1>
  }
 
   
}

export function areachart(data,xKey,yKey,Height){
  if (data && data.length>0) {
    const maxnbr = Math.max(...data.map(item=> item[yKey]))
    if (maxnbr<1) {
      var maxykeyvalue = maxnbr + maxnbr*0.1 // add 10% to the max value for add some space between the max yKey and top of chart 
    } else {
      var maxykeyvalue = Math.floor(maxnbr)+Math.floor(maxnbr)*0.1// add 10% to the max value for add some space between the max yKey and top of chart
    }
  return(
    <ResponsiveContainer width="100%"  height={Height}>                                                  
    <AreaChart
    data={data}
    margin={{
      top: 10,
      right: 30,
      left: 0,
      bottom: 0
    }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey= {xKey} />
    <YAxis domain={[0,maxykeyvalue]}/>  {/*   adjust the domain of the Y-axis to ensure that all data points are visibles */}
    <Tooltip />
    <Area type="monotone" dataKey={yKey} stroke="#8884d8" fill="#8884d8"/>
  </AreaChart>
  </ResponsiveContainer>
);
}else{
  return(
    <h1 style={{textAlign:"center"}}>Not available</h1>
  )
}
}


export function CustomizedLabelLineChart(data,xKey,yKey,Height){
  if (data && data.length>0) {
    const maxnbr = Math.max(...data.map(item=> item[yKey]))
    if (maxnbr<1) {
      var maxykeyvalue = maxnbr + maxnbr*0.1 // add 10% to the max value for add some space between the max yKey and top of chart 
    } else {
      var maxykeyvalue = Math.floor(maxnbr)+Math.floor(maxnbr)*0.1// add 10% to the max value for add some space between the max yKey and top of chart
    }
  return (
    <ResponsiveContainer width="100%"  height={Height}>                                                  
    <LineChart
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 0
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey= {xKey} height={60}  />
      <YAxis domain={[0,maxykeyvalue]}/>
      <Tooltip />
  
      <Line type="monotone" dataKey={yKey} stroke="#82ca9d" />
    </LineChart>
    </ResponsiveContainer>
  );
}}






export function AreaChartFillByValue(data,xKey,yKey,Height){
  

  const gradientOffset = () => {
    const dataMax = Math.max(...data.map(i => i[yKey]))
    const dataMin = Math.min(...data.map(i => i[yKey]))
  
    if (dataMax <= 0) {
      return 0
    }
    if (dataMin >= 0) {
      return 1
    }
  
    return dataMax / (dataMax - dataMin)
  }
  
  const off = gradientOffset()



  return(
    <ResponsiveContainer width="100%"  height={Height}>                                                  
    <AreaChart
    data={data}
    margin={{
      top: 10,
      right: 30,
      left: 0,
      bottom: 0
    }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey={xKey} />
    <YAxis />
    <Tooltip formatter={(value) =>{ return value>0 ? `+${value.toFixed(2)} %`:`${value.toFixed(2)} %`}}/>
    <defs>
      <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
        <stop offset={off} stopColor="green" stopOpacity={1} />
        <stop offset={off} stopColor="red" stopOpacity={1} />
      </linearGradient>
    </defs>
    <Area
      type="monotone"
      dataKey={yKey}
      stroke="#000"
      fill="url(#splitColor)"
    />
  </AreaChart>
  </ResponsiveContainer>
  );
}


export function SimpleBarChart(Data,xKey,yKey,Height){
   return(
    <ResponsiveContainer width="100%" height={Height}>
    <BarChart
      data={Data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
    
      <XAxis dataKey={xKey} />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar barSize={25} dataKey={yKey} fill="rgb(66, 176, 54)"  />
    </BarChart>
  </ResponsiveContainer>
   );
}


export function SimpleTreemap(Data,datakay,Height,Color){
  return (
    <ResponsiveContainer width="100%" height={Height}>
      <Treemap  data={Data} dataKey={datakay} aspectRatio={4 / 3} stroke="#fff" fill={Color} />
    </ResponsiveContainer>
  );
}
