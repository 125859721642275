import React ,{useEffect, useState} from "react";
import Unisatwallet from "./UnisatWallet";
import Xverswallet from "./XversWallet";
import { Button, Modal } from 'antd';



function Wallet(){
   
    const [ismodelwalletsopen, SetIsModelWalletsOpen] = useState(false);
    const [isunisatwallet, SetIsUnisatWallet] = useState(false);
    const [isxverswallet, SetIsXversWallet] = useState(false);
    
    const WalletAdrs = window.localStorage.getItem('Address');
    const WalletName = window.localStorage.getItem('WalletName');
      

    
    window.addEventListener('storage', () => {
      window.location.reload();
  })




    return(
    <>

     {isunisatwallet ?
     <div>
       <Unisatwallet/>  
     </div>
      :
      <>
      {isxverswallet ?
      <div>
       <Xverswallet/>  
      </div>
      :null
      }
      </>
      }


      {WalletAdrs  ?
       <>
        {WalletName === 'unisat' ?
        <div>
          <Unisatwallet/> 
        </div>
        :
        <div>
          <Xverswallet/> 
        </div>
        }
       </>
      :
      <>
     <div>
        <Button onClick={()=> SetIsModelWalletsOpen(true)}>Connect</Button>
     </div>

     <Modal title="Connect Wallet" footer="" centered open={ismodelwalletsopen}  onCancel={()=> SetIsModelWalletsOpen(false)}>

     <Button onClick={()=> {SetIsUnisatWallet(true) ; SetIsModelWalletsOpen(false)}} >Unisat</Button>
     <Button onClick={()=> {SetIsXversWallet(true) ; SetIsModelWalletsOpen(false)}} >Xvers</Button>

     </Modal>
     </>
      }
    </>
    );
}

export default Wallet;