import React, { useEffect, useState } from "react";
import axios from "axios";
import './Inscr_Coll.css';
import { Link } from "react-router-dom";
import HeaderPages from "./Header";
import * as Icon from 'react-bootstrap-icons';
import BotmPage from "./BottomPage";




 function IncriptionCollections(){

    const CollSlug = ['omb','bitcoin-frogs','pixel-pepes','sub-10k','dogepunks','ordibots','sub-100k','ordinal-birds','bitcoin-toadz','bitcoin-wizards','goosinals'];
    const [coll,SetColl] = useState([]);
    const [isloading,SetIsloading] =useState(true);


    const fthlstactv =async()=>{
        const res = await axios.get(`https://native.ordinals.market/sales`);
        const lastcoll = res.data.sales;
        const CollSlugCopy = [...CollSlug];
        for (let i = 0; i < lastcoll.length; i++) {
             if (!CollSlugCopy.includes(lastcoll[i].collectionId)) {
                CollSlugCopy.push(lastcoll[i].collectionId);
             }
        }
       return CollSlugCopy;        
    }


    const fetchColl=async()=>{  
       
       const _Collslug = await fthlstactv();    

        const Collections =[];
          const CollSlugcopy = [..._Collslug];
            for (let i = 0; i < CollSlugcopy.length; i++) {
            try {
            const res = await axios.get(`https://turbo.ordinalswallet.com/collection/${CollSlugcopy[i]}`);
            Collections.push({
             name:     res.data.name,
             slug:     res.data.slug,
             icon:     res.data.icon,
             hghtinsc: res.data.highest_inscription_num,
             socials:  res.data.socials
          });
        } catch (error) {
            // Remove the failed entry from CollSlugcopy
            CollSlugcopy.splice(i, 1);
            // Decrement i to stay at the same index in the next iteration
            i--;
        }
        }

        SetColl(Collections)
        SetIsloading(false)
           
    }

    
 
   useEffect(()=>{
    fetchColl();
   },[])

    return(
        <>
        <HeaderPages />
       
        <div className="bodypageInscColl">

        <h2>Collections</h2>  
        {isloading ? 
        <div className="Contloadercoll">
          <div className="loadercoll">
           <div className="loadercoll-text">Loading...</div>
           <div className="loadercoll-bar"></div>
          </div>
        </div>  
         :
         <div className="collections">          
          {coll.map((_Coll)=>(
           <div className="collection" key={_Coll?.hghtinsc}>

            <Link className="CollLink" to={`/CollectionInfo/${_Coll?.slug}`} target="_blank">

              <p>{_Coll?.name}</p>
              <img src={_Coll?.icon} alt={_Coll?.name}/>
              
            </Link> 

             <div className="buttondiv">
              { _Coll?.socials.twitter ?
             <a href={_Coll?.socials.twitter} target="_blank">
             <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"  viewBox="0 0 16 16">
              <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/>
             </svg>
              </a>
              :''}
              {_Coll?.socials.discord ?
              <a href={_Coll?.socials.discord} target="_blank">
               <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-discord" viewBox="0 0 16 16">
                 <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
               </svg>
              </a>
               :''}
              {_Coll?.socials.website ?
              <a href={_Coll?.socials.website} target="_blank">
               <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                 <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472M3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z"/>
               </svg>
               </a>
              :'' }
             </div>
             
           </div>
          ))}

          </div>
           }
        </div>

        <BotmPage/>
    </>
    );
 }

 export default IncriptionCollections;