
export function ConvDate(date){
  if (date) {
    const Timestamp = new Date(date * 1000) //convert Unix timestamp or another format into a more human-readable date and time format
const formattedDate = Timestamp.toLocaleDateString("en-US", { //Parse the date and time string into a JavaScript Date object. to convert date to 	D/M/Y, H:m:s AM
  month: "numeric",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});
return formattedDate;
  }
}



export function Condatemls(date){
  if (date) {
    const Timestamp = new Date(date) 
const formattedDate = Timestamp.toLocaleDateString("en-US", { //Parse the date and time string into a JavaScript Date object. to convert date to 	D/M/Y, H:m:s AM
  month: "short",
  day: "2-digit",
  year: "numeric",
});
return formattedDate;
  }
}



export const SimplifyNumber = (Sinbr) =>{ //this take large namber after decimale point and return simplify number  
  if (Sinbr>0) {
     if (Sinbr >= 0.1) {
  return  Number(Sinbr).toFixed(2);
  } else if (Sinbr >=0.001) {
      return Number(Sinbr).toFixed(3);
  } else {
       const [base,exp] = Number(Sinbr).toExponential().match(/(.*\..{0,1})(?:.*)(e.*)/).slice(-2); // this take the very small number and convert them to simplify exponential number 
     return  base.concat(exp);
  } 
  }
}

export const convertNumber = (Conbr)=>{
  if (Conbr>0) {
    if (1000<=Conbr && Conbr<1000000) {
    const cnbr = Conbr/1000;
    return (cnbr % 1 > 0.1 ? cnbr.toFixed(1) : cnbr.toFixed(0))+"K";
  } 
  if (Conbr>=1000000 && Conbr<1000000000) {
    const cnbr = Conbr/1000000;
    return (cnbr % 1 > 0.1 ? cnbr.toFixed(1) : cnbr.toFixed(0))+"M"; 
  }
  if (Conbr>=1000000000 && Conbr <1000000000000 ) {
    const cnbr = Conbr/1000000000;
    return (cnbr % 1 > 0.1 ? cnbr.toFixed(1) : cnbr.toFixed(0))+"B"; 
  }
  if (Conbr>=1000000000000) {
    const cnbr = Conbr/1000000000000;
    return (cnbr % 1 > 0.1 ? cnbr.toFixed(1) : cnbr.toFixed(0))+"T"; 
  }
  if (Conbr<1000 && Conbr>=0.01 ) {
    return Number(Conbr).toFixed(2);
  }
  if (Conbr<0.01) {
    return Number(Conbr).toFixed(8);
  }
  }
}

export const ShortAdrs =(_Adrs)=>{  //This code will extract the first 5 characters of the address, followed by three dots, and then the last 4 characters of the address. This will give you the desired shortened address format.
  if(_Adrs){
  const shortAdrs = _Adrs.substring(0, 5) + "..." + _Adrs.substring(_Adrs.length - 4);
  return shortAdrs;
}}

