import React from "react";
import { ShortAdrs } from "../Functions";
import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useNavigate } from "react-router-dom";
 

function Walletinfo({adrs}){


    const navigate = useNavigate();

    const NavigateProf =()=>{
      navigate('/Me');
    }


    const  WalletDisconnect = () => {

        window.localStorage.setItem('WalletName','');
        window.localStorage.setItem('Address','');
        window.localStorage.setItem('PublicKey','');

        window.dispatchEvent(new Event("storage"));
      };

      const items = [
        {
          label: 'Profile',
          key: '1',
          onClick: NavigateProf,
        },
        {
          label: 'Disconnect',
          key: '2',
          onClick: WalletDisconnect,
        },

      ]

      const menuProps = {
        items,
      };

   
    return(
        <>
         <div>
            <Dropdown.Button menu={menuProps} placement="bottomRight" arrow icon={<DownOutlined />}>
              {ShortAdrs(adrs)}
            </Dropdown.Button>
         </div>
        </>
    );
}

export default Walletinfo;